/*
 * @Author: kenjiding kenjiding@tencent.com
 * @Date: 2022-05-20 14:12:30
 * @LastEditors Please set LastEditors
 * @LastEditTime 2024-04-01 22:45:46
 */
import { sha1 } from 'js-sha1';
/**
 * 生成用户指纹
 */
export function getCanvasFingerprint() {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const txt = 'Canvas Fingerprinting';
    if (ctx !== null) {
        ctx.font = '14px Arial';
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'start';
        ctx.fillStyle = '#f60';
        ctx.fillRect(125, 1, 62, 20);
        ctx.fillStyle = '#069';
        ctx.fillText(txt, 2, 15);
        ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
        ctx.fillText(txt, 4, 17);
        // 转换为base64编码的data URL
        const dataURL = canvas.toDataURL();
        // 使用SHA1哈希函数来缩短指纹长度
        const hash = sha1(dataURL);
        return hash;
    }
    return '';
}
export function getUUID() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
export function jumpDemoSpace() {
    if (process.env.VUE_APP_PRIVITE_DEMO) {
        window.open('/wetable/default_biz', '_blank');
        return;
    }
    window.open('https://beacon.woa.com/wetable/demo1/file?tab=mBASQMfPYA9GAJ4g21LDZCc864P', '_blank');
}
export function jumpUserSpace() {
    window.open('https://docs.qq.com/form/page/DQ01TT1FiUWdsWUZL#/fill', '_blank');
}
