<template>
  <div class="bg-layer">
    <video
      autoplay loop muted
      src="../../../assets/images/bg-layer.webm"
    />
    <img src="../../../assets/images/mobel-bg.png">
  </div>
</template>

<script lang='ts' setup>

</script>

<style lang='less' scoped>
  .bg-layer {
    position: fixed;
    z-index: -1;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    video {
      min-width: 1920px;
      max-width: 1920px;
      min-height: 1080px;
      max-height: 1080px;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 800px;
      height: 1760px;
      display: none;
    }
  }

  @media screen and (max-width: 800px){
    .bg-layer {
      img {
        display: block;
      }
    }
  }
</style>
