import { computed } from 'vue';
import { useWindowScroll } from '@vueuse/core';
export function useBackTop(props) {
    const { y } = useWindowScroll();
    const showBackToTopVisible = computed(() => y.value > props.firstPageHeight);
    function backTopHandle() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
    return {
        showBackToTopVisible,
        backTopHandle,
    };
}
