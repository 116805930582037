<template>
  <div class="content-layout">
    <slot />
  </div>
</template>

<script lang='ts' setup>

</script>

<style lang='less' scoped>
  @content-width: 1260px;

  .content-layout {
    height: 100%;
    width: @content-width;
    margin: 0 auto;
  }

  @media screen and (min-width: 1100px) and (max-width: 1920px) {
    .content-layout {
      width: @content-width * 0.8;
    }
  }

  @media screen and (min-width: 850px) and (max-width: 1100px){
    .content-layout {
      width: @content-width * 0.68;
    }
  }

  @media screen and (min-width: 800px) and (max-width: 850px){
    .content-layout {
      width: @content-width * 0.57;
    }
  }

  @media screen and (max-width: 800px){
    .content-layout {
      width: 100%;
    }
  }
</style>
