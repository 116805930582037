import { computed, ref } from 'vue';
import { getThumnbsCount, setThumnbsCount } from '@/services/api';
import { getCanvasFingerprint } from '@/utils/util';
export function useThumsCount() {
    const isThumsed = ref(false);
    // 平台总点击数
    const thumsCount = ref(0);
    // 检查localStorage中是否有点赞记录
    if (localStorage.getItem('thumsCount')) {
        thumsCount.value = Number(localStorage.getItem('thumsCount') || 0);
        isThumsed.value = thumsCount.value !== 0;
    }
    let timer = 0;
    function setThumsHandle() {
        clearTimeout(timer);
        isThumsed.value = true;
        thumsCount.value += 1;
        setThumnbsCount({
            thumbsUpCount: 1,
            fingerprint: getCanvasFingerprint(),
        });
        timer = setTimeout(() => {
            localStorage.setItem('thumsCount', thumsCount.value.toString());
        }, 5000);
    }
    async function getThumsHandle() {
        const data = await getThumnbsCount();
        thumsCount.value = data;
    }
    getThumsHandle();
    const thumsCountText = computed(() => (thumsCount.value > 10000
        ? `${(thumsCount.value / 10000).toFixed(1)}万` : thumsCount.value));
    return {
        thumsCountText,
        isThumsed,
        setThumsHandle,
    };
}
