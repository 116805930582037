<template>
  <div class="statistics-panel">
    <header class="header-wrapper">
      <h2>列特征统计</h2>
    </header>
    <main class="content-wrapper">
      <img src="../../../../assets/images/img4-4.png">
    </main>
  </div>
</template>

<script lang='ts' setup>

</script>

<style lang='less' scoped>
  .statistics-panel {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 0.0625rem solid var(--grey-blue-3, #DCE3EE);
    background: var(--color-White00, #FFF);

    .header-wrapper {
      padding: 2rem 0 0.5rem 2rem;
      h2 {
        color: #122440;
        font-family: "PingFang SC";
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2rem;
      }
    }

    .content-wrapper {
      height: 0;
      flex: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
      }
    }
  }

  @media screen and (max-width: 800px){
    .statistics-panel {
      .header-wrapper {
        padding: 20px 20px 12px 20px;
        h2 {
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
  }
</style>
