import { nextTick, onMounted } from 'vue';
import image1 from '../../../../../assets/images/bubble-img-1.png';
import image2 from '../../../../../assets/images/bubble-img-2.png';
import image3 from '../../../../../assets/images/bubble-img-3.png';
import image4 from '../../../../../assets/images/bubble-img-4.png';
import image5 from '../../../../../assets/images/bubble-img-5.png';
import image6 from '../../../../../assets/images/bubble-img-6.png';
import image7 from '../../../../../assets/images/bubble-img-7.png';
export function useThumsCanvas(canvasRef) {
    let context = null;
    let width = 0;
    let height = 0;
    let scanning = false;
    const renderList = [];
    const scaleTime = 0.1; // 百分比
    const imgsList = [];
    onMounted(async () => {
        await nextTick();
        context = canvasRef.value.getContext('2d');
        width = canvasRef.value.width;
        height = canvasRef.value.height;
    });
    function loadImages() {
        const images = [
            image1,
            image2,
            image3,
            image4,
            image5,
            image6,
            image7,
        ];
        Promise.all(images.map(src => new Promise((resolve, reject) => {
            const img = new Image(80, 80);
            img.onload = () => imgsList.push(img);
            img.onerror = reject;
            img.src = src;
        })));
    }
    function getRandomInRange(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    function requestFrame(cb) {
        return (window.requestAnimationFrame || function (callback) {
            return window.setTimeout(callback, 1000 / 60);
        })(cb);
    }
    function createRender() {
        if (imgsList.length === 0)
            return null;
        const basicScale = [0.6, 0.9, 1.2][getRandomInRange(0, 2)];
        const getScale = (diffTime) => {
            if (diffTime < scaleTime) {
                return +((diffTime / scaleTime).toFixed(2)) * basicScale;
            }
            return basicScale;
        };
        // 随机读取一个图片来渲染
        const image = imgsList[getRandomInRange(0, imgsList.length - 1)];
        const offset = 20;
        const basicX = width / 2 + getRandomInRange(-offset, offset);
        const angle = getRandomInRange(2, 10);
        const ratio = getRandomInRange(10, 30) * ((getRandomInRange(0, 1) ? 1 : -1));
        const getTranslateX = (diffTime) => {
            if (diffTime < scaleTime) { // 放大期间，不进行摇摆位移
                return basicX;
            }
            return basicX + ratio * Math.sin(angle * (diffTime - scaleTime));
        };
        const getTranslateY = (diffTime) => image.height / 2 + (height - image.height / 2) * (1 - diffTime);
        const fadeOutStage = getRandomInRange(14, 18) / 100;
        const getAlpha = (diffTime) => {
            const left = 1 - +diffTime;
            if (left > fadeOutStage) {
                return 1;
            }
            return 1 - +((fadeOutStage - left) / fadeOutStage).toFixed(2);
        };
        return (diffTime) => {
            // 差值满了，即结束了 0 ---》 1
            if (diffTime >= 1 || !context)
                return true;
            context.save();
            const scale = getScale(diffTime);
            const translateX = getTranslateX(diffTime);
            const translateY = getTranslateY(diffTime);
            context.translate(translateX, translateY);
            context.scale(scale, scale);
            context.globalAlpha = getAlpha(diffTime);
            context.drawImage(image, -image.width / 2, -image.height / 2, image.width, image.height);
            context.restore();
        };
    }
    function scan() {
        if (!context)
            return;
        context.clearRect(0, 0, width, height);
        context.fillStyle = 'transparent';
        context.fillRect(0, 0, 200, 400);
        let index = 0;
        let { length } = renderList;
        if (length > 0) {
            requestFrame(scan.bind(null));
            scanning = true;
        }
        else {
            scanning = false;
        }
        while (index < length) {
            const child = renderList[index];
            if (!child?.render || child.render.call(null, (Date.now() - child.timestamp) / child.duration)) {
                // 结束了，删除该动画
                renderList.splice(index, 1);
                length -= 1;
            }
            else {
                index += 1;
            }
        }
    }
    function start() {
        const render = createRender();
        const duration = getRandomInRange(1500, 3000);
        renderList.push({
            render,
            duration,
            timestamp: Date.now(),
        });
        if (!scanning) {
            scanning = true;
            requestFrame(scan.bind(null));
        }
    }
    loadImages();
    return {
        start,
    };
}
