import { switchAppHandle } from '@/services/api';
// 进入页面时获取最近访问空间
// 提前记录，避免导致用户点击事件和打开页面tab之间存在时间差（有些浏览器会进行拦截）
let recentBizCode;
export async function initRecentBiz() {
    const list = await switchAppHandle('excelin', 0);
    recentBizCode = list.find(item => item.level === 'biz')?.id;
}
export function getRecentBizCode() {
    return recentBizCode;
}
