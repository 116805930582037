/*
 * @description icons
 * @module types
 */
import { getUUID } from '@/utils/util';
function getAllSvgAssets() {
    // 只有在 webpack 环境下才是通过 process.env 注入变量
    if (process.env.BASE_URL) {
        // webpack 构建逻辑
        const allSVGAssets2 = require.context('./', false, /\.svg$/);
        return allSVGAssets2.keys().reduce((acc, svgPath) => {
            Object.assign(acc, {
                // eslint-disable-next-line ts/no-require-imports
                [svgPath]: require(`${svgPath}?raw`),
            });
            return acc;
        }, {});
    }
    // vite 构建逻辑
    // eslint-disable-next-line ts/ban-ts-comment
    // @ts-expect-error
    return import.meta.glob('./*.svg', {
        query: '?raw',
        import: 'default',
        eager: true,
    });
}
// 读取当前目录下所有 svg
const allSVGAssets = getAllSvgAssets();
// 初始化 svg 图标map
export const svgIconMap = {};
Object.entries(allSVGAssets).forEach(([key, value]) => {
    const pattern = /([^/]+)(?=\.\w+$)/;
    const result = pattern.exec(key);
    if (result) {
        const fileNameWithoutExtension = result[1];
        svgIconMap[fileNameWithoutExtension] = value;
    }
    else
        console.error('No match found.');
});
/**
 * 转化 Svg
 */
export function transformSvgContent(svgContent, isSetSize, size) {
    let updateSvg = svgContent;
    updateSvg = replaceWidthHeight(updateSvg, isSetSize, size);
    updateSvg = replaceColor(updateSvg);
    updateSvg = replaceId(updateSvg);
    return updateSvg;
}
function replaceWidthHeight(svgContent, isSetSize, size) {
    if (isSetSize) {
        const regex = /width="(\d+)"\s+height="(\d+)"/;
        return svgContent.replace(regex, () => `width="${size}" height="${size}"`);
    }
    return svgContent;
}
function replaceColor(svgContent) {
    return svgContent.replace(/fill="black"/g, 'fill="currentColor"');
}
function replaceId(svgContent) {
    let updatedSvg = svgContent;
    svgContent.replace(/url\(#([^\\)]+)\)/g, (match, p1) => {
        const newUUID = getUUID();
        updatedSvg = updatedSvg.replaceAll(p1, newUUID);
        return '';
    });
    return updatedSvg;
}
