/*
 * @Author: kelvinywang
 * @Date: 2022-04-22 14:29:44
 * @LastEditTime 2024-06-05 11:37:37
 * @Description: 控制台应用入口
 */
import '@/assets/style';
import { createApp } from 'vue';
import App from './app.vue';
let app = null;
// vue实例渲染方法
function render() {
    app = createApp(App);
    app.config.warnHandler = () => null;
    app.mount('#app');
}
// micro app挂载方法
function mount() {
    render();
}
mount();
