import { reactive } from 'vue';
export function useWebsiteStore() {
    const state = reactive({
        demandVisible: false,
    });
    function toggleDemandVisible(visble) {
        state.demandVisible = visble;
    }
    return {
        state,
        toggleDemandVisible,
    };
}
