/*
 * @Author: hermanke
 * @Date: 2022-04-24 11:28:09
 * @LastEditors Please set LastEditors
 * @LastEditTime 2024-07-02 22:13:22
 * @Description: 封装 Axios
 */
import { Http } from './http';
export const initHttp = function initHttp() {
    const http = new Http({
        baseURL: '/',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        timeout: 150000,
    });
    // 设置拦截器
    http.setResponselifeCycles({
        checkResponse(res) {
            return res.status === 200 && (res.data.code === 0
                || res.data.beaconCode === '0'
                // 兼容 dataauth code 不同的情况
                || ((res.config.url ?? '').includes('/api/dataauth') && res.data.code === 1));
        },
        // 响应成功直接返回
        onResponseSuccess(res) {
            return res;
        },
        onResponseFail() {
            // 响应失败不做处理
        },
        afterResponse() {
            // 不做处理
        },
        handleResponseUnknowError(err) {
            return Promise.reject(err);
        },
    });
    return http;
};
export default initHttp();
