import { pick } from 'lodash-es';
import http from '@/utils/http';
/**
 * 提交反馈
 */
export async function submitFeedback(feetBackForm) {
    const params = pick(feetBackForm, ['contact', 'consultationQuestion', 'fingerprint']);
    const resp = await http.post('/api/suiteserver/web/leadCapture/wetable/submit', params, {
        headers: {
            ticket: feetBackForm.authParams.ticket,
            randomString: feetBackForm.authParams.randomString,
        },
    });
    return resp.data;
}
/**
 * 提交反馈到反馈中心
 */
export async function submitFeedbackToFeedbackCenter(feetBackForm) {
    const resp = await http.post('/api/suiteserver/web/feedback/submit', {
        categories: ['excelin'],
        channel: 'default',
        content: feetBackForm.consultationQuestion,
        feedbackType: 'story',
        images: [],
        refer: location.href,
    });
    return resp.data;
}
/**
 * 获取点赞数
 */
export async function getThumnbsCount() {
    const resp = await http.get('/api/suiteserver/web/leadCapture/wetable/thumbsUp', {
        disabledErrorTip: true,
    });
    return resp.data;
}
/**
 * 设置点赞数
 */
export async function setThumnbsCount(params) {
    const resp = await http.post('/api/suiteserver/web/leadCapture/wetable/thumbsUp', params);
    return resp.data;
}
/**
 * 获取最近访问的空间
 */
export async function switchAppHandle(appKey, corporationId) {
    const resp = await http.get('/api/suiteserver/web/recent/switchApp', {
        params: {
            appKey,
            corporationId,
        },
        disabledErrorTip: true,
    });
    return resp.data;
}
