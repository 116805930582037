import axios from 'axios';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
export class Http {
    axiosInstance;
    // 处理响应的生命周期
    responselifeCycles = {
        checkResponse: () => true,
        onResponseSuccess: res => res,
        onResponseFail: res => Promise.reject(res),
        afterResponse: res => res,
        handleResponseUnknowError: error => error,
    };
    constructor(config) {
        this.axiosInstance = axios.create(config);
        this.initResponseLifeCycles();
    }
    /**
     * 设置响应生命周期
     * @param responselifeCycles
     */
    setResponselifeCycles(responselifeCycles) {
        Object.assign(this.responselifeCycles, responselifeCycles);
    }
    /**
     * http请求的基础方法
     * R => 请求返回对象中data的类型
     * D => 请求参数对象中data的类型
     * @param config
     * @returns
     */
    async request(config) {
        // 这里不做错误处理，统一在拦截器中来做
        const res = await this.axiosInstance.request(config);
        return res?.data || {};
    }
    /**
     * http get 方法
     * @param url
     * @param config
     * @returns
     */
    async get(url, config) {
        const newConfig = {
            ...config || {},
            url,
            method: 'GET',
        };
        return this.request(newConfig);
    }
    /**
     * http post 方法
     * @param url
     * @param data
     * @param config
     * @returns
     */
    async post(url, data, config) {
        const newConfig = {
            ...config || {},
            url,
            method: 'POST',
            data,
        };
        return this.request(newConfig);
    }
    /**
     * http delete 方法
     * @param url
     * @param config
     * @returns
     */
    async delete(url, data, config) {
        const newConfig = {
            ...config || {},
            url,
            method: 'DELETE',
            data,
        };
        return this.request(newConfig);
    }
    /**
     * http put 方法
     * @param url
     * @param data
     * @param config
     * @returns
     */
    async put(url, data, config) {
        const newConfig = {
            ...config || {},
            url,
            method: 'PUT',
            data,
        };
        return this.request(newConfig);
    }
    /**
     * 初始化拦截器
     */
    initResponseLifeCycles() {
        // 请求接收拦截器
        this.axiosInstance.interceptors.response.use((res) => {
            const { checkResponse, onResponseSuccess, onResponseFail, afterResponse, } = this.responselifeCycles;
            const resStatus = checkResponse(res);
            // 接收成功守卫和失败守卫的处理结果
            let handleRes;
            if (resStatus)
                handleRes = onResponseSuccess(res);
            else
                handleRes = onResponseFail(res);
            // 如果处理结果存在直接返回
            if (handleRes)
                return handleRes;
            afterResponse(res, resStatus);
            return resStatus ? res : Promise.reject(res);
        }, error => this.responselifeCycles.handleResponseUnknowError(error));
    }
}
